import { Box, Flex } from '@rebass/grid'
import { Alert, Input, InputGroup } from '@tkxs/cast-ui'
import { Field, Formik } from 'formik'
import { getCsrfToken, signIn, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { CTitle } from '../common/cast-ui-fixes'
import { SButton, SLink } from '../common/common'
import * as Sentry from '@sentry/nextjs'
import Image from 'next/image'
import { loadingGif } from '../common/constants'
import axios from 'axios'
import { performRequest } from './api'
import Link from 'next/link'
import { exec } from 'child_process'

const StyledPage = styled.div`
  .page {
  }
`

interface Props {
  pageProps: any
  theme?: any
}

export default function Login({ csrfToken }) {
  const router = useRouter()

  const { data: session, status } = useSession()

  const [loginError, setLoginError] = useState(undefined)
  const [submitError, setSubmitError] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    if (process.env.NEXT_APP_ENV === 'development') {
      setShowAlert(true)
    }
    Sentry.configureScope(function (scope) {
      scope.setUser(null)
    })
    if (router.query.error) {
      Sentry.configureScope(function (scope) {
        scope.setUser(null)
        scope.setLevel(Sentry.Severity.Log)
        Sentry.captureMessage(router.query.error.toString())
      })
      setLoginError(router.query.error.toString())
    }
  }, [router])

  if (session || status === 'authenticated') return null

  try {
    localStorage.clear()
  } catch (e) {}
  return (
    <StyledPage>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={(values, formikBag) => {
          const { email, password } = values
          setSubmitError(null)
          signIn('credentials', {
            redirect: false,
            email,
            password,
            callbackUrl: '/',
          }).then(function ({ error, status, ok, url }) {
            if (error) {
              Sentry.captureException(error)
            }
            if (status >= 400 && !ok) {
              setSubmitError(
                'Wrong credentials. Try again or contact support for help.',
              )
              formikBag.setSubmitting(false)
              Sentry.configureScope(function (scope) {
                scope.setLevel(Sentry.Severity.Log)
                Sentry.captureMessage(
                  'Wrong credentials. Try again or contact support for help.',
                )
              })
            } else {
              performRequest(`/api/cache?clear=true`)
              const { returnTo } = router.query
              router.push(returnTo?.toString() || '/')
            }
          })
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required('Email is required')
            .email('This is not a valid email address'),
          password: Yup.string().required('Password is required'),
        })}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize={true}
        validateOnMount={true}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setSubmitting,
          } = props
          return (
            <>
              <form>
                <Flex
                  width={'100%'}
                  alignItems={'center'}
                  height={'100vh'}
                  justifyContent={'center'}
                  flexWrap="wrap"
                >
                  <Box width={[1, 1 / 3]} mb={3}>
                    <Box width={[1, 3 / 4]} margin={'auto'}>
                      <Image
                        placeholder="blur"
                        blurDataURL={loadingGif}
                        src="/images/tezza-logo-1.png"
                        alt="Tezza"
                        width={237}
                        height={75}
                      />
                      <br />
                      {showAlert && (
                        <Alert alertStyle="warning" lightMode>
                          This is a <b>TEST</b> environment. Go to{' '}
                          <a href="https://humvee.tezzasolutions.com/">
                            <u>https://humvee.tezzasolutions.com/</u>
                          </a>{' '}
                          if you want to update your actual tasks and
                          timesheets.
                        </Alert>
                      )}
                      <CTitle size={20}>Sign In</CTitle>
                      <br />
                      <input
                        name="csrfToken"
                        type="hidden"
                        defaultValue={csrfToken}
                      />
                      <InputGroup label="Email Address">
                        <Field name={'email'} id={'email'}>
                          {({ field, form, meta }) => (
                            <Input
                              {...field}
                              id="email"
                              type="email"
                              value={values[field.name]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={meta.touched && meta.error}
                              invalidText={meta.error}
                              inputSize="lg"
                              autoComplete="off"
                              required={true}
                              addonText={
                                meta.touched && meta.error ? '*' : null
                              }
                              addonTextPosition="right"
                            />
                          )}
                        </Field>
                      </InputGroup>
                      <InputGroup label="Password">
                        <Field name={'password'} id={'password'}>
                          {({ field, form, meta }) => (
                            <Input
                              {...field}
                              id="password"
                              type="password"
                              value={values[field.name]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={meta.touched && meta.error}
                              invalidText={meta.error}
                              inputSize="lg"
                              autoComplete="new-password"
                              required={true}
                              addonText={
                                meta.touched && meta.error ? '*' : null
                              }
                              addonTextPosition="right"
                            />
                          )}
                        </Field>
                      </InputGroup>
                      <br />
                      {submitError && (
                        <Alert alertStyle="danger" lightMode>
                          {submitError}{' '}
                          <a
                            href="mailto:support@tezzasolutions.com"
                            target="_blank"
                            rel="noreferrer"
                          >
                            support@tezzasolutions
                          </a>
                        </Alert>
                      )}
                      {successMessage && (
                        <Alert alertStyle="success" lightMode>
                          {successMessage}
                        </Alert>
                      )}
                      <br />
                      <Flex justifyContent={'start'} alignItems={'center'}>
                        <Box width={[1, 1 / 2]}>
                          <SButton
                            id="signInBtn"
                            btnStyle="primary"
                            btnSize="lg"
                            disabled={!isValid || isSubmitting}
                            onClick={async (e) => {
                              e.preventDefault()
                              //await setFieldValue("submitType", "new");
                              handleSubmit()
                            }}
                            type="submit"
                          >
                            {isSubmitting ? (
                              <span>Signing in...</span>
                            ) : (
                              <span>Sign In</span>
                            )}
                          </SButton>
                        </Box>
                        <Box width={[1, 1 / 3]} ml={3}>
                          <Link href="/forgot" passHref>
                            <SLink>Forgot password?</SLink>
                          </Link>
                        </Box>
                      </Flex>
                    </Box>
                  </Box>
                </Flex>
              </form>
            </>
          )
        }}
      </Formik>
    </StyledPage>
  )
}

// This is the recommended way for Next.js 9.3 or newer
export async function getServerSideProps(context) {
  return {
    props: {
      csrfToken: await getCsrfToken(context),
    },
  }
}
